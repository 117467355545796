// initialise javascript
import {
    GOVUKInitAll,
    HMRCInitAll,
    ClearCookies,
} from 'dvla-external-frontend';

require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure dvla-external-frontend/dist/media are included in the compiled pack
require.context('dvla-external-frontend/dist/media', true);

GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();
